import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;
    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner = () => (
  <Wrapper>
    <ColumnH1 label="記事タイトルタイトル記事タイトルタイトル記事タイトルタイトル記事タイトルタイトル" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.01.01</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <h1>
        h1テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
      </h1>
      <p>
        記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト
      </p>
      <img src="/images/column/details/column0001.jpg" alt="キンクラコラム" />
      <h2>h2テキストテキストテキストテキストテキストテキストテキスト</h2>
      <p>
        記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト
      </p>
      <h3>h3テキストテキストテキストテキストテキストテキストテキスト</h3>
      <p>
        記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト
      </p>
      <h3>h3テキスト</h3>
      <p>
        記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト記事本文テキスト
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner
