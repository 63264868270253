import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../components/atoms/columnBackAndForth'
import SEO from '../../components/atoms/seo'
import { ITopicPath } from '../../components/atoms/topicPath'
import ColumnDetailInner from '../../components/molecules/columnDetailInner'
import ColumnRelationCards from '../../components/molecules/columnRelationCards'
import UsefulFunctionLayout from '../../components/molecules/usefulFunctionLayout011'
import ColumnBody from '../../components/organisms/columnBody'
import ColumnLayout from '../../components/organisms/columnLayout'
import useRouteParam from '../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const ColumnDetailPage = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title:
        '記事タイトルタイトル記事タイトルタイトル記事タイトルタイトル記事タイトルタイトル',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO title="記事タイトルタイトル記事タイトルタイトル記事タイトルタイトル記事タイトルタイトル" />
      <ColumnBody>
        {/** 記事本体 */}
        <ColumnDetailInner />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards />
      </ColumnBody>
    </ColumnLayout>
  )
}

export default ColumnDetailPage

export const pageQuery = graphql`
  query ColumnDetailPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
