import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 40px 0 60px;

  a {
    border-radius: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #3ec7b3;
    margin: 0 3px;
    font-weight: 600;
    color: #3ec7b3;

    &.forth,
    &.back {
      width: 120px;
      height: 40px;

      span {
        margin: 0 5px;
      }
    }

    &.list {
      width: 120px;
      height: 50px;
    }

    &:hover {
      opacity: 1;
      background: #3ec7b3;
      color: #ffffff;
      text-decoration: underline solid rgba(255, 255, 255, 0);
    }

    &.off {
      border-color: #e5e5e5;
      color: #e5e5e5;

      &:hover {
        background: #ffffff;
        color: #e5e5e5;
      }
    }
  }
`

/**
 * 記事前後移動ボタンセット
 * @param props
 * @constructor
 */
const ColumnBackAndForth = (): JSX.Element => (
  <Wrapper>
    {/** TODO 川上申し送り事項：前の記事に遷移します。遷移する記事がない場合は off を付けます
         <Link className="forth off" to="/column/list"
         &lt;<span>前の記事</span>
         </Link>> */}
    {/** TODO 川上申し送り事項：記事一覧に遷移します */}
    <Link className="list" to={useRouteParam('/column/list/')}>
      記事一覧
    </Link>
    {/** TODO 川上申し送り事項：前の記事に移動します。移動する記事がない場合は off を付けます
         <Link className="back" to="/column/list">
         <span>次の記事</span>&gt;
         </Link> */}
  </Wrapper>
)

export default ColumnBackAndForth
