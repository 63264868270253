import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div``

/**
 * SVGアイコンコンポーネント
 * 羽ペン
 */
const WingSvg = (): JSX.Element => (
  <Wrapper className="svg-icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path
        d="M188.107,4.847c-11.748,1.621-22.994,23.66-28.53,33.861-1.392,2.566,1.038,3.9,2.469,1.445,1.029-1.764,3.536-6.883,4.892-6.618,6.475,1.079,9.929-3.144,7.477-5.8,8.237.169,12.321-4.362,9.984-7.115a12.336,12.336,0,0,0,9.225-1.058C200.368,15.882,199.96,3.212,188.107,4.847Z"
        transform="translate(-158.161 -2.702)"
        fill="#243650"
      />
    </svg>
  </Wrapper>
)
export default WingSvg
